import React from 'react';
import styled from 'styled-components';
import {
  LogoSpotifyForArtists,
  VisuallyHidden,
  screenMdMin,
  screenSmMax,
} from '@spotify-internal/encore-web';
import { useT } from '@spotify-internal/i18n-core';

type LogoProps = {
  condensed?: boolean;
};

const StyledLogo = styled(LogoSpotifyForArtists).attrs((props: LogoProps) => ({
  height: props.condensed ? 28 : 36,
  semanticColor: 'backgroundBase',
}))`
  @media (max-width: ${screenSmMax}) {
    display: ${(props: LogoProps) => (props.condensed ? 'initial' : 'none')};
  }

  @media (min-width: ${screenMdMin}) {
    display: ${(props: LogoProps) => (props.condensed ? 'none' : 'initial')};
  }
`;

export const ResponsiveLogo: React.FC = () => {
  const t = useT();
  const label = t('FRODOR_bfbc75', 'Spotify for Artists', '');
  return (
    <>
      <StyledLogo aria-label={label} />
      <StyledLogo condensed aria-label={label} />
      <VisuallyHidden>{label}</VisuallyHidden>
    </>
  );
};
